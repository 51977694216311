:root {
  --main-text-color: #d8e1e7
}

body {
  font-family: 'Open Sans', sans-serif;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

a {
  color: var(--main-text-color);
  font-weight: bold;
}

.background-image {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  overflow: hidden;
}

.video{
  position: fixed;
  width: 1200px;
  right: 0;
  bottom: 0;
  /* display: none; */
  min-width: 100%; 
  min-height: 100%;
}

/* Add some content at the bottom of the video/page */
.content {
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
}

.instructions {
  text-align: left;
  color: var(--main-text-color);
  font-size: 18px;
  padding: 30px;
  white-space: pre-line;
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

/* Style the buttons used to pause/play the video */
.buttons {
  display: flex;
  width: 30%;
  justify-content: flex-end;
  margin: 30px;
}

.button {
  width: 200px;
  font-size: 17px;
  font-family: 'Open Sans', sans-serif;
  font-style: italic;
  padding: 10px;
  border: none;
  border-radius: 10px;
  background: #000;
  color: #fff;
  cursor: pointer;
  padding-right: 20px;
}

.button:first-child {
  margin-right: 20px;
}

.button:hover {
  background: #2a3a30;
}

.pexels-attribution {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 13px;
  color: #fff;
  padding: 8px;
  border-radius: 5px;
  background: rgba(42, 58, 48, 0.25);
}

/* .right-group {
  width: 70%;
  display: flex;
} */

.row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex: 1 1 100%;
}

.credit-text {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  width: 70%;
  align-items: flex-end;
  margin: 1%;
}
